<!--
 * @Description: 历史报告入口页面
 * @Author: yangsheng
 * @Date: 2021-11-08 15:08:07
 * @LastEditors: liutq
 * @LastEditTime: 2023-03-17 15:09:06
-->
<template>
    <div class="page">
        <van-loading v-if="loading" :size="50" type="circular" color="rgb(100, 100, 100)" />
    </div>
</template>

<script>
import { findScanTypeInfo, findCurrentMember, findScanTrueHistory } from '@/assets/js/apolloGql.js'

export default {
    data() {
        return {
            loading: true,
            scanType: null
        }
    },
    created() {
        window.localStorage.removeItem('modelInfo')
        // 保存入口类型
        window.sessionStorage.setItem('entryType', 3)
        // 历史报告scanid
        const scanId = this.$route.query.scan_id

        this.findScanTypeInfo(scanId)

    },
    methods: {
        // 历史记录接口
        findScanTrueHistory(deviceType) {
            this.$apollo
                .query({
                    query: findScanTrueHistory,
                    variables: {
                        deviceType: deviceType
                     },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    let history = res.data.findScanTrueHistory.data
                    window.localStorage.setItem('createTime', JSON.stringify(history.createTime))
                })
                .catch(err => {
                    console.log(err)
                })
        },
        // 发起合成
        async findScanTypeInfo(scanId) {
            const result = await this.$apollo
                .query({
                    query: findScanTypeInfo,
                    fetchPolicy: 'network-only',
                    variables: {
                        scanId: scanId
                    },
                })

            const resultData = result.data.findScanTypeInfo

            if (resultData.code === 200) {
                const userResult = await this.$apollo
                    .query({
                        query: findCurrentMember,
                        fetchPolicy: 'network-only'
                    })
                const userResultData = userResult.data.findCurrentMember
                if (userResultData.data.id === resultData.data.memberId) {
                    // 保存扫描信息
                    this.updateModelInfo(resultData.data)
                    // 获取设备最新扫描时间
                    this.findScanTrueHistory(resultData.data.deviceType)
                    // 跳转到报告详情页面
                    this.$router.push({
                        path: '/report'
                    })
                } else {
                    // 跳转到首页
                    this.$router.push({
                        path: '/'
                    })
                }
            } else if (resultData.code === 404) {
                // 获取不到最新报告跳转到无报告页面
                this.$router.push({
                    path: '/exception/report'
                })
            } else {
                this.$router.push({
                    path: '/exception/report'
                })
            }
        },
        // 更新modelInfo，缓存
        updateModelInfo(data) {
            const modelInfo = {
                scanId: data.scanId,
                memberId: data.memberId,
                biaMeasure: data.biaMeasure,
                staticEval: data.staticEval,
                createTime: data.createTime,
                shoulderEval: data.shoulderJointEval,
                girthMeasure: data.girthMeasure,
                biaStatus: data.bmBiaScanInfo ? data.bmBiaScanInfo.result : 4,
                bdaStatus: data.bmBdaScanInfo ? data.bmBdaScanInfo.result : 4,
                evalStatus: data.bsScanInfo ? data.bsScanInfo.result : 4,
                bsShoulderScanResult: data.bsShoulderScanInfo ? data.bsShoulderScanInfo.result : 4,
                scanTime: data.scanTime,
                deviceId: data.deviceId
            }
            window.localStorage.setItem('modelInfo', JSON.stringify(modelInfo))
            window.localStorage.setItem('deviceType', JSON.stringify(data.deviceType))
        }
    }
}
</script>

<style scoped lang="less">
.page {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#262849), to(#07090f));
    background-image: linear-gradient(180deg, #262849, #07090f);
}
</style>
